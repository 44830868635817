<template>
	<div>
		<v-dialog v-model="successDialog" persistent max-width="602px" height="324px" @keydown="handleKeyDown" >
			<v-card>
				<v-card-title v-if="publishGreenIcon" class="d-flex justify-center align-items-center" style="background-color: #34c873">
					<img :src="publishGreenIcon" />
				</v-card-title>
				<v-divider />
				<v-card-text class="d-flex justify-center align-items-center flex-column" style="height: 126px" >
					<div class="ma-2" style="font-size: 24px;font-weight: 500;font-style: normal;color: #000000;line-height: 29.05px;word-wrap: break-word;">
						{{ messageText }}
					</div>
				</v-card-text>
				<v-card-actions class="d-flex justify-center align-items-center flex-column" style="height:126px ">
					<div class="d-flex justify-center align-items-center ma-2" >
						<v-btn style="background-color: #34c873; color: #ffffff; width: 160px;" @click="clickYes()">OK</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: ["successObject"],
	components: {},
	data() {
		return {
			cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
			publishGreenIcon: "",
			successDialog: false,
			messageText: '',
		};
	},
	methods: {
		handleKeyDown(event) {
			if (event.keyCode === 27) {
				this.clickYes();
			}
		},
		clickYes() {
			this.successDialog = false;
			this.$emit("closeSuccessDialog", true);
		},
	},
	mounted() {
		let successObj = this.$props.successObject;
		this.messageText = successObj.messageText;
		if(successObj.publishSuccess) {
			this.publishGreenIcon = this.cloudFront + "publishGreenIcon.svg";
		}
		this.successDialog = successObj.showMessageDialog;
	},
};
</script>